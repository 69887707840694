<script>
import AppTag from "@/components/ui/AppTag.vue";
import AppDropdownOption from "@/components/ui/AppDropdownOption.vue";
import AppLoader from "@/components/ui/AppLoader.vue";

import { flags } from "@/components/jsons/flags.js"

export default {
  name: "AppDropdown",

  components: {
    AppTag,
    AppDropdownOption,
    AppLoader
  },

  props: {
    multiple: { type: Boolean, default: false },
    value: { type: [Array, String, Number, Object]},
    label: { type: String, default: "" },
    description: { type: String, default: "", required: false },
    options: { type: [Array, Object], required: true },
    placeholder: { type: String, default: "Select..." },
    limitDisplay: { type: Number, default: 3 },
    leftIcon: { type: String, default: "", required: false },
    onCheckIcon: { type: Boolean, default: false },
    prependIcon: { type: String, default: '' },
    offCheckIcon: { type: Boolean, default: false },
    returnObject: { type: Boolean, default: false },
    itemText: { type: [String, Function], default: "" },
    itemDescription: { type: String, default: "" },
    itemValue: { type: String, default: "" },
    autocomplete: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    clearable: { type: Boolean, default: false },
    itemsLimit: { type: Number },
    hideIcon: { type: Boolean, default: false },
    errorText: { type: String, default: null },
    stickyCountry: { type: Boolean, default: false },
    countryFlags: { type: Boolean, default: false },
    createBtn: { type: Boolean, default: false },
    isCreatingNew: { type: Boolean, default: false }
  },

  data() {
    return {
      showDropdown: false,
      searchQuery: "",

      inputValue: '',
      error: false,
      errorMessage: null,
      arrFlags: flags.map(flag => ({
        ...flag,
        src: require(`@/assets/img/icons/flags/${flag.src}`)
      }))
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.inputValue = this.getItemText(val);
      },
    },

    errorText: {
      immediate: true,
      handler(val) {
        this.error = !!val;
        this.errorMessage = val;
      },
    }
  },

  computed: {
    selectedItems: {
      get() {
        return this.multiple ? this.value : [this.value];
      },
      set(val) {
        this.$emit("input", this.multiple ? val : val[0]);
      },
    },

    displayedItems() {
      return this.selectedItems.slice(0, this.limitDisplay);
    },

    selectedOptions() {
      return this.filteredOptions.filter(item => this.isSelected(item));
    },

    unselectedOptions() {
      return this.filteredOptions.filter(item => !this.isSelected(item));
    },

    hiddenItemsCount() {
      return this.selectedItems.length - this.limitDisplay;
    },

    selectedItemsText() {
      return this.selectedItems.map(item => {
        if (typeof item === 'object') {
          return this.getItemText(item);
        }
        const foundItem = this.options.find(option => this.getItemValue(option) === item);
        return foundItem ? this.getItemText(foundItem) : !this.itemValue[0] ? item : null;
      });
    },

    filteredOptions() {
      if (this.searchQuery) {
        const normalizedSearchQuery = this.searchQuery.toLowerCase();

        if (this.itemText) {
          return this.options.filter((option) => {
            const itemText = this.getItemText(option);
            return itemText && itemText.toLowerCase().includes(normalizedSearchQuery);
          });
        } else {
          return this.options.filter((option) =>
            option && option.toLowerCase().includes(normalizedSearchQuery)
          );
        }
      }
      return this.options;
    },

    listLength() {
      if (this.itemsLimit) {
        return this.selectedItems.length < this.itemsLimit 
      } else {
        return this.unselectedOptions
      }
      
    },

    filteredCountries() { 
      return this.options.filter((country) => country.id == 0)
    },
  },

  mounted() {
    if (this.value.length > 0) {
      if (typeof this.value === 'object') {
        this.selectItem(this.value)
        this.selectValue(this.value)
      } else {
        this.inputValue = this.value
      }
    }
  },

  methods: {
    toggleDropdown() {
      // this.multiple ? this.showDropdown = true : this.showDropdown = !this.showDropdown;
      this.showDropdown = true
    },

    selectItem(item) {
      const itemValue = this.getItemValue(item);
      if (this.multiple) {
        const index = this.selectedItems.findIndex((selected) =>
          this.getItemValue(selected) === itemValue
        );
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        } else {
          this.selectedItems.push(this.returnObject ? item : itemValue);
        }
      } else {
        this.selectedItems[0] = item;
        this.showDropdown = false;
      }
      this.searchQuery = ""

      this.calculateItem()
    },

    calculateItem() {
      let emittedValue;
      if (this.multiple) {
        emittedValue = this.selectedItems;
      } else if (this.returnObject) {
        emittedValue = this.selectedItems[0];
      } else {
        emittedValue = this.itemValue.length > 0 ? this.selectedItems[0][this.itemValue] : this.selectedItems[0]
      }

      this.$emit("input", emittedValue);
    },

    selectValue(item) {
      if (this.editable || this.autocomplete) {
        this.inputValue = this.getItemText(item)
      }
      this.onBlur()
    },

    removeItem(index) {
      this.selectedItems.splice(index, 1);
      this.$emit("input", this.multiple ? this.selectedItems : this.selectedItems[0])
    },

    clickOutsideMenu() {
      this.showDropdown = false;
    },

    getItemValue(item) {
      if (item !== null) {
        if (typeof (item) === 'object') {
          return this.itemValue ? item[this.itemValue] : this.getItemText(item);
        } else {
          return item;
        }
      }
    },

    isSelected(item) {
      const itemValue = this.getItemValue(item);
      return this.selectedItems.some(selected => this.getItemValue(selected) === itemValue);
    },

    getItemText(item) {
      if (item !== null) {
        return item[this.itemText] || this.getDisplayTag(item);
      }
    },

    getDisplayTag(item) {
      if (typeof this.itemText === 'function') {
        return this.itemText(item)
      } else if (typeof item === 'object') {
        return item[this.itemText]
      } else {
        return item
      }
    },

    isEmpty(selectedItems) {
      if (Array.isArray(selectedItems)) {
        return selectedItems.length === 0;
      } else if (typeof selectedItems === 'string') {
        return selectedItems.trim() === '';
      } else if (selectedItems && typeof selectedItems === 'object') {
        return Object.keys(selectedItems).length === 0;
      } else {
        return !selectedItems;
      }
    },

    handleInput() {
      if (this.autocomplete && !this.multiple) {
        this.searchQuery = this.inputValue;
      }
    },

    resetSelected() {
      this.selectedItems = []
    },

    clearSelectItem(){
      this.selectedItems[0] = ''
    },

    onBlur() {
      if (!!this.errorText === false) {
          this.validate();
      }
    },

    validate() {
      let value = this.editable || (this.autocomplete && !this.multiple) ? this.inputValue : this.selectedItems
      let isValid = true;
      this.errorMessage = null
      this.error = false
      for (const rule of this.rules) {
        const result = rule(value);
        if (result !== true) {
          this.error = true
          isValid = false;
          this.errorMessage = result;
          break;
        }
      }
      return isValid;
    },

    onCreateItem(){
      this.showDropdown = false
      this.$emit('changeTitle')
      this.$emit('onCreatingNew', true)
      this.$refs.input.focus()
    },

    stickyOptionCountry() {

      if (!this.stickyCountry) {
        return
      }

      const dropdownMenu = this.$refs.dropdownMenu
      const dropdownMenuRect = dropdownMenu.getBoundingClientRect().top
      const dropdownOptions = this.$refs.dropdownOption
      const currentClientRect = dropdownOptions[0].$el.getBoundingClientRect().top - dropdownMenuRect
      let currentStickyId = null

      if (currentClientRect === 47) {
        dropdownOptions.forEach((item) => {
          item.$el.classList.remove('sticky-item')
        })
        currentStickyId = null
        return
      }

      dropdownOptions.forEach((item) => {
        const itemTop = item.$el.getBoundingClientRect().top;
        if (itemTop <= dropdownMenuRect && item.$attrs.id.id == 0) {
          if (currentStickyId !== item.$attrs.id.country_id) {
            dropdownOptions.forEach((opt) => {
              opt.$el.classList.remove('sticky-item')
            })
            item.$el.classList.add('sticky-item')
            currentStickyId = item.$attrs.id.country_id
          }
        }
      })
    },

    getFlagBackgroundStyle(item) {
      const flag = this.arrFlags.find(flag => flag.id == item.country_id && item.id == 0)
      if (flag) {
        return flag.src
      }
    },

    clearInput() {
      this.$emit('clearInput');
    },
  },
};
</script>

<template>
  <div class="dropdown" v-click-outside="clickOutsideMenu">
    <label v-if="label">{{ label }}
      <span v-if="required">*</span>
    </label>
    <div
      @focusout="onBlur"
      class="dropdown-container"
      :class="{ 'loading' : loading, 'dropdown-container-error' : errorMessage && error }"
      @click="toggleDropdown"
      ref="myElement"
    >
      <img v-if="leftIcon" alt="left-icon" class="left-icon" :src="leftIcon">
      <div :class="multiple ? 'selected-items' : 'selected-text'">
        <template v-if="multiple">
          <template v-if="selectedItems[0]">
            <AppTag
              v-for="(item, index) in displayedItems"
              :key="index"
              :label="itemValue[0] ? selectedItemsText[index] : getDisplayTag(item)"
              close
              color="blue"
              class="selected-items-tag"
              @close="removeItem(index)"
            />
          </template>
          <template v-else><span class="selected-items__placeholder">{{ placeholder }}</span></template>
        </template>

        <template v-else-if="!editable && !autocomplete">
          <span class="selected-items__placeholder" v-if="isEmpty(selectedItems[0])">
            {{ placeholder }}
          </span>
          <span class="selected-items__text" v-else>
            {{ itemValue[0] ? selectedItemsText[0] : getDisplayTag(selectedItems[0]) }}
            <span @click="clearSelectItem" v-show="clearable"><img src="@/assets/img/icons/cancel.svg" alt="cancel"/></span>
          </span>
        </template>
        <template v-else-if="editable || autocomplete">
          <input
            type="text"
            class="dropdown-input"
            v-model="inputValue"
            @input="$emit('changeInput', inputValue), handleInput(), toggleDropdown()"
            @blur="$emit('blur', inputValue)"
            ref="input"
            @focus="clearInput"
          >
        </template>

        <div class="hidden-count" v-if="multiple && hiddenItemsCount > 0">
          <AppTag
            :label="'+' + hiddenItemsCount"
            color="blue"
          />
        </div>
      </div>
      <AppLoader v-if="loading" class="loader" :size="24"/>
      <img v-if="!hideIcon" alt="chevron" class="dropdown-icon" :class="{ rotated: showDropdown }"
           src="@/assets/img/icons/chevron-black.svg">
    </div>

    <small class="dropdown-description">
      <span class="description" v-if="description && !showDropdown && !error">{{ description }}</span>
      <span class="error-message" v-if="errorMessage && error">{{ errorMessage }}</span>
    </small>

    <div class="dropdown-menu" ref="dropdownMenu" v-if="showDropdown && !isCreatingNew && (autocomplete && multiple ? options.length > 0 : filteredOptions.length > 0)" @scroll="stickyOptionCountry">
      <div class="dropdown-search-wrapper" v-if="autocomplete && multiple">
        <img src="@/assets/img/icons/search-black.svg" alt="search-icon" class="search-icon">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Поиск"
          class="dropdown-search"
          @input="$emit('searchInput', searchQuery)"
        />
      </div>
      <AppDropdownOption
        v-for="(item, index) in selectedOptions"
        :key="index "
        :on-check-icon="onCheckIcon"
        :off-check-icon="offCheckIcon"
        :item="itemText"
        :description="item[itemDescription]"
        :prepend-icon="prependIcon"
        :display-tag="getDisplayTag(item)"
        :is-selected="isSelected(item)"
        @select="selectItem(item), selectValue(item)"
      />

      <div class="dropdown-menu__clear" v-if="multiple && selectedItems[0]" @click="resetSelected">
        Удалить выбранные
      </div>
     <div class="dropdown__unselect-list" v-if="listLength">
        <AppDropdownOption
          v-for="(item, index) in unselectedOptions"
          :key="index + getDisplayTag(item)"
          :on-check-icon="onCheckIcon"
          :off-check-icon="offCheckIcon"
          :item="itemText"
          :description="item[itemDescription]"
          :prepend-icon="prependIcon"
          :display-tag="getDisplayTag(item)"
          :is-selected="isSelected(item)"
          @select="getDisplayTag(item).length > 0 ? [selectItem(item), selectValue(item)] : ''"
          :id="item"
          ref="dropdownOption"
        >
          <template v-if="item.id == 0 && countryFlags"><img class="dropdown-flag" :src="getFlagBackgroundStyle(item)"/> </template>
        </AppDropdownOption>
        <div v-if="createBtn" class="create-item-list" @click="onCreateItem">
          Создать нового
        </div>
      </div>
    </div>
    <div v-else-if="showDropdown && unselectedOptions.length == 0 && !isCreatingNew" class="dropdown-menu">
      <p class="not-found">Не найдено</p>
       <div v-if="createBtn" class="create-item-list" @click="onCreateItem">
          Создать нового
        </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.dropdown {
  display: flex;
  flex-direction: column;
  font-family: $font-family;

  label {
    color: var(--grey-70);
    margin-bottom: 4px;
    @include font-description-1;

    > span {
      color: var(--red-60);
    }
  }

  input {
    border: none;
    outline: none;
    flex-grow: 1;
    padding: 0;
    width: 100%;
    @include font-subtitle-medium;
  }

  small {
    color: var(--grey-60);
    margin-top: 4px;
    height: 10px;
    @include font-description-1;

    > span {
      &.error-message {
        color: var(--red-100)
      }
    }
  }

  &-container {
    border: 1px solid var(--grey-40);
    border-radius: 12px;
    background-color: #FFFFFF;
    display: flex;
    padding: 6px 8px 6px 12px;
    height: 40px;
    cursor: pointer;
    transition: border 0.2s;
    position: relative;

    &:hover {
      border: 1px solid var(--grey-70);
    }

    &:hover {
      border: 1px solid var(--grey-70);
    }

    &:focus-within {
      border: 1px solid var(--blue-70);
      box-shadow: 0 0 0 2px rgba(178, 207, 241, 0.50);
    }

    &-error {
      border: 1px solid var(--red-100);
      box-shadow: 0 0 0 2px rgba(255, 137, 146, 0.50);
    }

    &.loading {
      opacity: 0.5;
    }
  }
  &__unselect-list{
    position: relative;
  }
}

.selected-items {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  gap: 4px;
  max-width: calc(100% - 8px);
  position: relative;

  &__placeholder {
    color: var(--grey-50);
    align-self: center;
    @include font-description-0;
  }

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 2px);
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: $tablet-width){
      font-size: 14px;

      img{
        height: 18px;
        width: 18px;
      }
    }
  }
}

.selected-text {
  display: grid;
  width: 100%;
  margin-right: 4px;
}

.selected-items-tag {
  white-space: nowrap;

  &:not(:first-child) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 2px;
  }
}

.loader {
  opacity: 1 !important;
  z-index: 10;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hidden-count {
  margin-right: 4px;
  @include font-description-0;
}

.dropdown-icon {
  margin-left: auto;
  color: var(--grey-70);
  transition: transform 0.3s ease;
}

.left-icon {
  height: 16px;
  margin: 6px 8px 6px 0;
}

.dropdown-menu {
  margin-top: 60px;
  max-height: 255px;
  overflow-y: auto;
  position: absolute;
  z-index: 10;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid var(--grey-50);
  background: #FFF;
  backdrop-filter: blur(15px);

  &__clear {
    display: flex;
    width: 100%;
    padding: 4px 8px 8px 12px;
    justify-content: flex-end;
    align-items: center;
    color: var(--grey-70);
    text-align: right;
    line-height: 20px;
    cursor: pointer;
    transition-duration: 0.2s;
    @include font-description-semibold-0;

    &:active {
      color: var(--grey-60);
    }
  }
}

.dropdown-search-wrapper {
  display: flex;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 4px;
  border-bottom: 1px solid var(--grey-50);

  &:focus-within {
    border-radius: 8px;
    border: 1px solid var(--Brand-Blue70, #305CA8);
    box-shadow: 0 0 0 2px rgba(178, 207, 241, 0.50);
  }

  .search-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .dropdown-search {
    width: 100%;
    padding: 6px 16px 4px 0;
    height: 32px;
    margin-bottom: 4px;
    border: none;
    outline: none;

    &::placeholder {
      color: var(--grey-50);
      line-height: 20px;
      @include font-description-semibold-0;
    }
  }
}

.rotated {
  transform: rotate(180deg);
}

.clearable{

.selected-items__text{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

    img{
      width: 20px;
      height: 20px;
    }
  }
}
.create-item-list {
  @include font-description-semibold-0;
  color: var(--blue-70);
  text-align: center;
  padding: 10px 0 6px;
  position: sticky;
  bottom: -10px;
  cursor: pointer;
  background-color: #FFF;
  z-index: 10;
  height: 40px;

  &:before {
    content: "";
    position: absolute;
    border: 0.5px solid var(--grey-50);
    width: 100%;
    left: 0;
    top: 0;
  }
}

.not-found {
  @include font-body-3;
  padding: 10px 16px;
  color: var(--grey-60);
}
.sticky-item {
  position: sticky;
  top: -10px;
  background: white;
  border-bottom: 2px solid var(--blue-20);
  border-radius: unset;
  z-index: 10;
  height: 40px;
} 

.dropdown-flag{
  width: 27px;
  height: 17px;
  object-fit: cover;
  margin-left: 8px;
  border: .1px solid var(--grey-40);
}
</style>