<script>
import AppDropdown from "@/components/ui/AppDropDown.vue";
import AppInput from "@/components/ui/AppInput.vue";
import AppDropFile from "@/components/ui/AppDropFile.vue";
import { order_request_form_new } from "@/components/jsons/order-request-form-new";

export default {
  name: "RequestFormProduct",
  components: {
    AppDropdown,
    AppInput,
    AppDropFile,
  },

  props: {
    rate: { type: Object, required: true },
    storeData: { Object, default: null },
    order_key: Number
  },

  data() {
    return {
      elements: order_request_form_new.goods_info,
      showLoad: false,
      valid: false,
      acceptedFiles: ".PDF, .JPG, .JPEG, .DOC, .DOCX,.XLS, .XLSX",
      selected_goods: [],
      files: [],
      formdata: {
        gds_name: "",
        gds_gross_weight: "",
        gds_volume: '',
      },
      goodsData: [],
    };
  },

  watch: {
    storeData: {
      handler() {
        this.fillFromStore()
      },
      deep: true,
    },
  },

  beforeDestroy() {
    this.elements.gds_gross_weight.rules.pop()
  },

  computed: {
    filteredObj() {
      return Object.fromEntries(
        Object.entries(this.elements).filter(([key]) => key !== 'gds_name')
      );
    },
  },

  mounted() {
    this.getGoodsData()
    this.calculateWeight()
  },

  methods: {
    getGoodsData() {
      this.showLoad = true
      this.$postapi(this.$address + this.$getters.getClientReferenceData.uri, {
        method: this.$getters.getClientReferenceData.name,
        reference_name: "ref_client_goods",
        client_id: this.$_getsetting("client_id"),
      }).then((data) => {
        if (data.error == 0) {
          this.goodsData = JSON.parse(data.message);
          this.showLoad = false
        }
      });
    },

    calculateWeight() {
      let maxWeight = 28
      if (this.rate.rate.unit_code === '20\'DV') {
        maxWeight = 24
      }

      if (this.rate.rate.unit_code === '20DV Heavy') {
        this.formdata.gds_gross_weight = '25';
      }

      this.elements.gds_gross_weight.rules.push(v => (v <= maxWeight) || 'Вес не должен быть более ' + maxWeight + ' тонн')
    },

    fillFromStore() {
      if (this.storeData) {
        this.formdata.gds_name = this.storeData.gds_name || ''
        this.formdata.gds_gross_weight = this.storeData.gds_gross_weight || ''
        this.formdata.gds_volume = this.storeData.gds_volume || ''
        this.selected_goods = this.storeData.gds_name || []
      }
    },

    validate() {
      const inputs = this.$refs.formInputs;
      this.valid = true

      inputs.forEach(input => {
        const isValid = input.validate()
        if (!isValid) {
          this.valid = false
        }
      });

      !this.$refs.inputGdsName.validate() ? this.valid = false : ''
      return this.valid
    },

    fillProduct(data) {
      this.formdata.gds_name = data
    },

    fileUploaded(value) {
      this.files = value
    },

    fileUpdate() {
      this.$emit('updateFile', this.files)
    },

    OnEditChange() {
      return this.formdata
    },
  },
};
</script>
<template>
  <div class="product-wrapp">
    <p class="order-form-title">Выбор товара</p>
    <v-form class="product-form" ref="goodsForm" v-model="valid">
      <AppInput 
        v-if="goodsData.length > 0"
        v-model="formdata.gds_name"
        class="product-form__section"
        :suggestions="goodsData.map(item => item.gds_name)"
        :label="elements.gds_name.caption"
        :rules="elements.gds_name.rules"
        ref="inputGdsName"
        required
        @input="$emit('validateForm')"
      />

      <AppInput
        v-else
        v-model="formdata.gds_name"
        :label="elements.gds_name.caption"
        :rules="elements.gds_name.rules"
        ref="inputGdsName"
        required
        @input="$emit('validateForm')"
      />

      <AppInput
        v-for="element in filteredObj"
        ref="formInputs"
        v-model="formdata[element.name]"
        :key="element.name"
        :label="element.caption"
        :rules="element.rules"
        :required="element.required"
        @input="$emit('validateForm')"
      />

    <AppDropFile
      class="product-form__file"
      :label="'Загрузите документ'"
      :accept="acceptedFiles"
      :temp_id="order_key"
      @fileUploaded="fileUploaded"
    />
  </v-form>
  </div>
</template>

<style scoped lang="scss">
.product-form {
  min-height: 600px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, min-content);
  grid-gap: 16px 16px;

  @media (max-width: $mobile-width) {
    display: flex;
    flex-direction: column;
    grid-gap: 16px 16px;
  }

  &__section {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    position: relative;
    width: 100%;
  }

  &__file {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
  }
}
</style>