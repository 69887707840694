<script>
export default {
  name: 'AppDropdownOption',
  props: {
    isSelected: Boolean,
    item: [Object, String, Function],
    onCheckIcon: Boolean,
    offCheckIcon: Boolean,
    prependIcon: String,
    displayTag: String,
    description: String,
  },


};
</script>

<template>
  <div
    class="dropdown-option"
    :class="{ 'selected': isSelected}"
    @click.stop="$emit('select', item)"
  >
    <div
      v-if="onCheckIcon || offCheckIcon"
      class="dropdown-option-checkbox"
      :class="{ 'checked': onCheckIcon && isSelected, 'unchecked': offCheckIcon &&!isSelected }"
    >
    </div>
    <img v-if="prependIcon" :src="prependIcon" class="dropdown-option__icon" alt="prepend-icon"/>
    <div class="dropdown-option__text">
      <span class="dropdown-option__text-ttl" :class="{ 'selected' : isSelected, 'selected-blue' : isSelected && description}">{{ displayTag }}</span>
      <span v-if="description" class="dropdown-option__text-description" :class="{ 'selected' : isSelected }">{{ description }}</span>
    </div>
    <slot></slot>
</div>
</template>

<style scoped lang="scss">
.dropdown-option {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 4px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 4px;
  margin: 4px 0;

  &:hover {
    background-color: var(--grey-20);
  }

  &.selected {
    background-color: var(--blue-10);
    border-radius: 4px;

    span {
      cursor: pointer;
    }
  }

  &-checkbox {
    width: 12px;
    height: 10px;
    border-radius: 4px;
    margin-right: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s, border-color 0.2s;

    &.checked {
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        background-image: url('../../assets/img/icons/check-blue.svg');
        background-size: cover;
        position: absolute;
      }
    }

    &.unchecked {
      &::after {
        content: '';
        opacity: 0.2;
        width: 100%;
        height: 100%;
        background-image: url('../../assets/img/icons/check-blue.svg');
        background-size: cover;
        position: absolute;
      }
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  &__text {
    display: flex;
    flex-direction: column;

    &-ttl {
      color: var(--grey-70);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      &.selected {
        font-weight: 600;
        color: var(--grey-100);
      }

      &.selected-blue {
        font-weight: 600;
        color: var(--blue-70);
      }
    }

    &-description {
      color: var(--grey-60);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;

      &.selected {
        color: var(--grey-70);
        font-weight: 500;
      }
    }
  }
}
</style>
