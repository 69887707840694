export const order_request_form_new =
  {
    name: 'OrderRequestForm',
    method: 'setOrderRequest',
    
    main_info: {
      place_from_name: {
        caption: "Место отправки",
        name: "place_from_name",
        disabled: false,
        alt: "",
        type: "text",
        required: true,
      },
      
      cont_type: {
        caption: "Тип оборудования",
        name: "cont_type",
        disabled: false,
        alt: "Укажите количество контейнеров в коносаментной партии",
      },
      
      place_to_name: {
        caption: "Место доставки",
        name: "place_to_name",
        disabled: false,
        alt: "Укажите количество контейнеров в коносаментной партии",
        type: "text",
        required: true,
      },
      
      line_name: {
        caption: "Линия",
        name: "line_name",
        disabled: false,
        required: true,
        alt: "Укажите количество контейнеров в коносаментной партии",
        type: "text"
      },
      
      port_name: {
        caption: "Порт прибытия",
        name: "port_name",
        disabled: false,
        alt: "Укажите количество контейнеров в коносаментной партии",
        type: "text"
      },
      
      exp_name: {
        caption: "Экспедитор в порту",
        name: "exp_name",
        disabled: false,
        alt: "Укажите количество контейнеров в коносаментной партии",
        type: "text"
      },
      
      drop_place: {
        caption: "Место Drop Off",
        name: "drop_place",
        disabled: false,
        required: true,
        alt: "Укажите количество контейнеров в коносаментной партии",
        type: "text"
      },
      
      order_num: {
        caption: "Номер заявки",
        name: "order_num",
        type: "text",
        rules: [
          v => (!v || v.length <= 50) || 'Номер должен быть не более 50 символов',
          forbiddenCharactersRule
        ]        
      },
      
      schedule: {
        caption: "Судно",
        name: "schedule",
        rules: [
          v => !!v || 'Судно обязательно',
          v => (v[0] && v.length > 0) || 'Судно обязательно',
        ],
      },

      agent_name: {
        caption: "Агент",
        name: "agent_name",
      },
      
      special_condition: {
        caption: "Дополнительные комментарии к заявке",
        name: "special_condition",
        type: "text",
        lines: 2,
        rules: [
          forbiddenCharactersRule
        ]
      },

    },
    
    consignee_info: {
      consignee_inn: {
        caption: "Инн получателя",
        name: "consignee_inn",
        type: "number",
        required: true,
        rules: [
          v => !!v || 'Инн обязателен',
          v => (!v || v.length >= 10) || 'ИНН должен состоять минимум из 10 символов',
          v => (v.length <= 15) || 'ИНН должен быть не более 15 символов',
          v => /^\d+$/.test(v) || 'ИНН должен содержать только цифры',
          forbiddenCharactersRule
        ],
      },
      
      consignee_name:{
        caption: "Наименование получателя",
        name: "consignee_name",
        type: "text",
        required: true,
        rules: [
          v => !!v || 'Наименование обязательно',
          v => (v && v.length >= 3) || 'Название должно быть не менее 3 символов',
          value => {
            const pattern = /[`']/g;
            const invalidChars = Array.from(new Set(value.match(pattern)));
            return !invalidChars.length
              ? true
              : `Присутствуют запрещенные символы: ${invalidChars.join(', ')}`;
          },
        ],
      },
      
      consignee_address:{
        caption: "Адрес получателя",
        name: "consignee_address",
        type: "text",
        required: true,
        rules: [
          v => !!v || 'Адрес обязателен',
          v => (v && v.length >= 3) || 'Адрес должен быть не менее 10 символов',
          forbiddenCharactersRule
        ],
      },
    },
    
    goods_info: {
      gds_name: {
        caption: "Наименование товара",
        name: "gds_name",
        disabled: false,
        alt: "",
        required: true,
        type: "text",
        rules: [
          v => !!v || 'Необходимо указать наименование товара',
          forbiddenCharactersRule
        ],
      },
      
      gds_gross_weight: {
        caption: "Предполагаемый вес брутто (тонны) на 1 КТК",
        name: "gds_gross_weight",
        disabled: false,
        alt: "",
        type: "number",
        required: true,
        rules: [
          v => !!v || 'Необходимо указать вес товара',
          v => (v.length <= 2) || 'Вес должен быть не более 2-х символова',
          v => /^\d+$/.test(v) || 'Вес должен содержать только цифры',
        ],
      },
      
      gds_volume: {
        caption: "Предполагаемый объём, м^3 на 1 КТК",
        name: "gds_volume",
        disabled: false,
        required: false,
        alt: "",
        type: "number",
        rules: [
          v => (v.length <= 5 || v.length === 0) || 'Объём должен быть не более 5-х символова',
          v => (/^\d+$/.test(v) || v.length === 0 ) || 'Объём должен содержать только цифры',
        ],
      },
    },

    extras: {
      is_vtt: {
        caption: "Оформление ВТТ",
        name: "is_vtt",
        alt: "Требуется оформление таможенного транзита в процессе перевозки.",
        type: "checkbox"
      },

      is_hbl: {
        caption: "Домашний коносамент",
        name: "is_hbl",
        alt: "Необходим коносамент от агента для получения релиза в порту.",
        type: "checkbox"
      },

      is_inshurance: {
        caption: "Организация страхования груза",
        name: "is_inshurance",
        type: "checkbox"
      },

      is_broker: {
        caption: "Таможенное оформление",
        name: "is_broker",
        type: "checkbox"
      },

      is_party: {
        caption: "Коносаментная партия",
        name: "is_party",
        alt: "Потребуется оформление коносаментной партии – несколько контейнеров будут отправлены на идентичных условиях и оформлены в одном коносаменте. ",
        type: "checkbox",
        children: {
          caption: "Коносаментная партия",
          name: "cont_cnt",
          type: "number",
          placeholder: "Количество контейнеров",
          required: true,
          alt: "Укажите количество контейнеров в коносаментной партии",
          rules: [
            v => !!v || 'Укажите количество контейнеров',
            v => Number(v) !== 0 || 'Неверное количество',
            v => !v || v === '0' || /^\d*[1-9]\d*$/.test(v) || 'Допустимо только пусто, 0 или положительное число',
            forbiddenCharactersRule,
            value => {
              const pattern = /^[^+\-.,e]+$/;
              return pattern.test(value) || "Присутствуют запрещённые символы"
            },
          ],
        }
      },

      is_exw: {
        caption: "Доставка до порта выхода",
        alt: "Требуется доставка до порта выхода.",
        name: "is_exw",
        type: "checkbox",
        children: {
          caption: "Адрес места забора груза",
          name: "pickup_address",
          alt: "Обозначение, адрес и контакты конечного склада для вывоза товара.",
          type: "text",
          required: true,
          rules: [
            v => !!v || 'Необходимо указать адрес',
            v => (v && v.length >= 9) || 'Адрес должен быть не менее 10 символов',
            forbiddenCharactersRule
          ],
        }
      },

      is_car: {
        caption: "Автовывоз на склад получателя",
        alt: "Требуется доставка до порта выхода.",
        name: "is_car",
        type: "checkbox",
        children: {
          caption: "Город доставки",
          name: "delivery_to",
          alt: "Город доставки",
          type: "select",
          required: true,
          rules: [
            v => !!v || 'Необходимо указать город доставки',
            forbiddenCharactersRule
          ],
          error: false,
          error_message: null,
        }
      },
    },
    
    auto_delivery: {
      warehouse_name: {
        caption: "Наименование склада",
        name: "warehouse_name",
        type: "text",
        required: false,
        rules: [
          v => !!v || 'Необходимо указать наименование склада',
          forbiddenCharactersRule
        ],
      },
      
      warehouse_contact: {
        caption: "Контактное лицо",
        name: "warehouse_contact",
        type: "text",
        required: false,
        rules: [
          v => !!v || 'Укажите ФИО получателя',
          forbiddenCharactersRule
        ],
      },
      
      warehouse_phone: {
        caption: "Телефон",
        name: "warehouse_phone",
        type: "text",
        required: false,
        rules: [
          v => (v && v.replace(/\D/g, '').length >= 10) || 'Укажите телефон целиком',
        ],
      },
      
      warehouse_address: {
        caption: "Адрес склада",
        name: "warehouse_address",
        alt: "Обозначение, адрес и контакты конечного склада для вывоза товара.",
        type: "text",
        required: false,
        rules: [
          v => !!v || 'Необходимо указать адрес склада',
          forbiddenCharactersRule
        ],
      },
      
      // warehouse_recip_name: {
      //   caption: "ФИО получателя",
      //   name: "warehouse_recip_name",
      //   alt: "Обозначение, адрес и контакты конечного склада для вывоза товара.",
      //   type: "text",
      //   required: true,
      //
      // },
    },
    
    exw_info: {
    
    },
    
    
    invoice_number: {
      caption: "Номер инвойса",
      name: "invoice_number",
      type: "number"
    },
    
  
    shipper_name: {
      caption: "Наименование отправителя",
      name: "shipper_name",
      type: "text",
      required: true,
      rules: [
        v => !!v || 'Наименование обязательно',
        v => (v && v.length >= 3) || 'Название должно быть не менее 3 символов',
      ],
    },
    
    shipper_email: {
      caption: "E-mail отправителя",
      name: "shipper_email",
      type: "email",
      required: true,
      rules: [
        v => !!v || 'Наименование обязательно',
        v => (v && v.length >= 3) || 'Название должно быть не менее 3 символов',
        forbiddenCharactersRule
      ],
    },
    
    shipper_phone: {
      caption: "Телефон отправителя",
      name: "shipper_phone",
      type: "number",
      required: true,
    },
    
    is_car: {
      caption: "Нужна автодоставка",
      name: "is_car",
      alt: "Требуется автодоставка на конечный склад.",
      type: "boolean"
    },
    
    is_protected: {
      caption: "Охрана груза",
      name: "is_protected",
      type: "boolean"
    },
    
    
    is_exchange_differences: {
      caption: "Оплата до выгрузки",
      name: "is_exchange_differences",
      type: "boolean",
    },
    
    is_post_paying: {
      caption: "Оплата после выгрузки",
      name: "is_post_paying",
      type: "boolean",
      // disabled: true,
      messages: "* данная опция доступна после выгрузки первого ктк, для ее активации свяжитесь с менеджером Vedexx",
    },
    
    delivery_from: {
      caption: "Откуда",
      name: "delivery_from",
      alt: "Город отправки",
      type: "text",
      required: true,
      rules: [
        v => !!v || 'Необходимо указать адрес город отправки',
        forbiddenCharactersRule
      ],
    },
    
    delivery_to: {
      caption: "Город доставки",
      name: "delivery_to",
      alt: "Город доставки",
      type: "text",
      required: true,
      rules: [
        v => !!v || 'Необходимо указать город доставки',
        forbiddenCharactersRule
      ],
      error: false,
      error_message: null,
    },
    
    pickup_address: {
      caption: "Адрес места забора груза",
      name: "pickup_address",
      alt: "Обозначение, адрес и контакты конечного склада для вывоза товара.",
      type: "text",
      required: true,
      rules: [
        v => !!v || 'Необходимо указать адрес',
        v => (v && v.length >= 9) || 'Адрес должен быть не менее 10 символов',
        forbiddenCharactersRule
      ],
    },
    
    ready_date: {
      caption: "Дата выхода",
      name: "ready_date",
      disabled: false,
      alt: "",
      type: "date"
    },
    
    order_file: {
      caption: "Документы для оформления заявки",
      name: "order_file",
      multiple: true,
      disabled: true,
      alt: "Прикрепите документы для оформления",
    },
    
    
  
    
    // agent_id: {
    //   caption: "Агент",
    //   listfield: "agent_name",
    //   keyfield: "agent_id",
    //   multiple: false,
    //   name: "agent_id",
    //   items: [],
    //   default: 0,
    //   reference: 'ClientVDRefAgents',
    //   alt: "",
    // },
    fraight_currency_id: {
      caption: "Валюта оплаты фрахта",
      listfield: "cur_name",
      keyfield: "numcode",
      multiple: false,
      name: "fraight_currency_id",
      items: [],
      default: 0,
      reference: 'ClientVDRefCurrency',
      alt: "Стоимость морской перевозки и валюта оплаты. Условия оплаты уточняйте с менеджером.",
    },
    
    methods: {
      extractValues(data) {
        let result = {}
        if (data.controls.name == data.element) {
          for (let element of Object.keys(data.controls)) {
            if (data.value[data.controls[element].name] != undefined) {
              if (data.value[data.controls[element].name].length > 0) {
                if (data.value[data.controls[element].name][0][data.controls[element].keyfield] != undefined) {
                  for (let i = 0; i < data.value[data.controls[element].name].length; i++) {
                    result[data.controls[element].name] = data.value[data.controls[element].name][i][data.controls[element].keyfield]
                  }
                } else {
                  result[data.controls[element].name] = data.value[data.controls[element].name]
                }
              }
            }
          }
        }
        result.method = data.controls.method
        return { name: data.controls.name, values: result }
      },
    },
  }

  function forbiddenCharactersRule(value) {
    if (!value) return true;
  
    const pattern = /[`"']/g;
    const invalidChars = Array.from(new Set(value.match(pattern)));
  
    return !invalidChars.length
      ? true
      : `Присутствуют запрещенные символы: ${invalidChars.join(', ')}`;
  }
  
  
