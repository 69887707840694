<script>
import AppInput from "@/components/ui/AppInput.vue";
import AppDropDown from "@/components/ui/AppDropDown.vue";
import AppButton from "@/components/ui/AppButton.vue";

import { order_invoice_form_spec } from "@/components/jsons/order-invoice-form";

export default {
  name: 'ConsigneeInfoForm',
  components: {
    AppDropDown,
    AppInput,
    AppButton
  },

  props: {
    rate: { type: Object, required: true },
    edit_form: { type: Boolean, default: false },
    invoice: { type: Object },
  },

  data() {
    return {
      elements: order_invoice_form_spec,
      valid: false,
      selected_invoice: [],
      addShipperNameInput: false,
      shippersData: [],
      showLoader: false,
      disabledInputs: false,

      formdata: {
        invoice_number: "",
        shipper_email: "",
        shipper_phone: "",
        shipper_name: "",
        shipper_fio: "",
        shipper_address: "",
        gds_cost: '',
      },
      title: 'Выбор отправителя',
      isCreatingNew: false,
    }
  },

  mounted() {
    this.getShippersData()
    this.valid = false

    if (this.edit_form) {
      this.fillEditInvoice()
    }
  },

  computed: {
    filteredObj() {
      return Object.fromEntries(
        Object.entries(this.elements).filter(([key]) => key !== 'shipper_name')
      );
    },

    showNameInput() {
      return this.edit_form && this.selected_invoice.length === 0;
    }
  },

  methods: {
    validateForm() {
      const inputs = this.$refs.formInputs;
      this.valid = true;

      inputs.forEach(input => {
        const isValid = input.validate();
        if (!isValid) {
          this.valid = false;
        }
      });

      !this.$refs.inputShprName.validate() ? this.valid = false : ''
      if (this.valid === true) {
        this.saveForm()
      }
    },

    saveForm() {
      this.edit_form ? this.$emit('editInvoice', this.formdata) : this.$emit('addInvoice', this.formdata)
    },

    fillEditInvoice() {
      this.selected_invoice = []
      this.formdata.shipper_name = this.invoice.shipper_name
      this.formdata.shipper_email = this.invoice.shipper_email
      this.formdata.shipper_phone = this.invoice.shipper_phone
      this.formdata.shipper_fio = this.invoice.shipper_fio
      this.formdata.shipper_address = this.invoice.shipper_address
      this.formdata.invoice_number = this.invoice.invoice_number
    },

    fillInvoice(data) {
      this.formdata.shipper_name = data.shpr_name
      this.formdata.shipper_email = data.shpr_email
      this.formdata.shipper_phone = data.shpr_phone
      this.formdata.shipper_fio = data.shpr_contact
      this.formdata.shipper_address = data.shpr_addr
      this.disabledInputs = true
      this.validateForm()
    },

    getShippersData() {
      this.showLoader = true
      this.$postapi(this.$address + this.$getters.getClientReferenceData.uri, {
        method: this.$getters.getClientReferenceData.name,
        reference_name: 'ref_client_shippers',
        client_id: this.$_getsetting('client_id'),
      }).then(data => {
        if (data.error === 0) {
          this.shippersData = JSON.parse(data.message)
          this.showLoader = false
        }
      });
    },

    clearFields(){
      this.formdata.shipper_name = ''
      this.formdata.shipper_email = ''
      this.formdata.shipper_phone = ''
      this.formdata.shipper_fio = ''
      this.formdata.shipper_address = ''
      this.formdata.invoice_number = ''

      this.selected_invoice = []
      this.disabledInputs = false
    },

    onResetTitle(){
      this.title = 'Выбор отправителя'
    },

    onChangeTitle(){
      this.title = 'Новый отправитель'
      this.clearFields()
    },
    
    onCreatingNew(value) {
      this.isCreatingNew = value;
    },
  }
}
</script>
<template>
  <v-form
    ref="invoiceForm"
    v-model="valid"
    @submit.prevent="validateForm"
  >
  <p class="order-form-title">{{ title }}</p>
    <div class="cv-grid-div mt-4 mb-3">
      <AppDropDown
        v-if="shippersData.length > 0"
        class="request-form__input"
        :label="elements.shipper_name.caption"
        :options="shippersData"
        item-text="shpr_name"
        item-value="shpr_id"
        ref="inputShprName"
        :rules="elements.shipper_name.rules"
        required
        editable
        return-object
        v-model="formdata.shipper_name"
        autocomplete
        placeholder="Выберите отправителя"
        @changeInput="formdata.shipper_name = $event"
        @input="fillInvoice"
        createBtn
        @changeTitle="onChangeTitle"
        @onCreatingNew="onCreatingNew"
        :isCreatingNew="isCreatingNew"
        :hideIcon="isCreatingNew"
       />

      <AppInput
        v-else
        ref="inputShprName"
        class="request-form__input"
        v-model="formdata.shipper_name"
        :label="elements.shipper_name.caption"
        :loading="showLoader"
        :rules="elements.shipper_name.rules"
        required
      />

      <AppInput
        v-for="element in filteredObj"
        :key="element.name"
        class="request-form__input"
        ref="formInputs"
        v-model="formdata[element.name]"
        :label="element.caption"
        :rules="element.rules"
        :editable="(disabledInputs && element.editable) || edit_form"
        :required="element.required"
      />
    </div>

    <div class="request-form__bottom">

      <AppButton
        label="Сохранить"
        color="secondary-blue"
        class="request-form__btn"
        @click="validateForm"
      />

      <AppButton
        label="Отменить"
        color="grey"
        class="request-form__btn"
        v-if="!invoice"
        @click="clearFields(), onResetTitle(), onCreatingNew(false)"
      />

      <AppButton
        label="Отменить"
        color="grey"
        class="request-form__btn"
        v-if="invoice"
        @click="() => this.$emit('hideFormInvoice')"
      />

    </div>
  </v-form>
</template>
<style scoped lang="scss">
.request-form {
  &__alert-link {
    font-weight: 700;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      opacity: 0.9;
    }
  }

  &__input {
    width: 100%;
    position: relative;
  }

  &__btn {
    width: 33%;
    position: relative;
  }
}

.cv-grid-div {
  display: grid;
  z-index: 400;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  align-content: flex-start;
  grid-column-gap: 20px;
  grid-row-gap: 12px;
  justify-items: flex-start;

  //:last-child {
  //  grid-column: 1 / -1;
  //}

  &:last-child {
    padding-bottom: 235px;
  }

  @media (max-width: $mobile-width) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.request-form__bottom{
  display: flex;
  justify-content: flex-end;
  gap: 0 12px;
}

.request-form__btn{
  max-width: 130px;

  @media (max-width: $mobile-width){
    width: 100%;
  }
}

</style>
