export const flags = [
    { id: 6, title: 'Алжир', src: 'algeria.webp' },
    { id: 13, title: 'Аргентина', src: 'argentina.webp' },
    { id: 18, title: 'Бангладеш', src: 'bangladesh.webp' },
    { id: 20, title: 'Бахрейн', src: 'bahrain.webp' },
    { id: 24, title: 'Бенин', src: 'benin.webp' },
    { id: 31, title: 'Бразилия', src: 'brazil.webp' },
    { id: 42, title: 'Вьетнам', src: 'vietnam.webp' },
    { id: 47, title: 'Гана', src: 'ghana.webp' },
    { id: 64, title: 'Джибути', src: 'djibouti.webp' },
    { id: 67, title: 'Египет', src: 'egypt.webp' },
    { id: 71, title: 'Израиль', src: 'israel.webp' },
    { id: 72, title: 'Индия', src: 'india.webp' },
    { id: 73, title: 'Индонезия', src: 'indonesia.webp' },
    { id: 74, title: 'Иордания', src: 'jordan.webp' },
    { id: 75, title: 'Ирак', src: 'iraq.webp' },
    { id: 81, title: 'Йемен', src: 'yemen.webp' },
    { id: 84, title: 'Камбоджа', src: 'cambodia.webp' },
    { id: 85, title: 'Камерун', src: 'cameroon.webp' },
    { id: 87, title: 'Катар', src: 'qatar.webp' },
    { id: 88, title: 'Кения', src: 'kenya.webp' },
    { id: 92, title: 'Китай', src: 'china.webp' },
    { id: 94, title: 'Колумбия', src: 'colombia.webp' },
    { id: 96, title: 'Конго', src: 'republic_of_the_congo.webp' },
    { id: 99, title: 'Корея', src: 'north_korea.webp' },
    { id: 103, title: 'Кувейт', src: 'kuwait.webp' },
    { id: 108, title: 'Ливан', src: 'lebanon.webp' },
    { id: 109, title: 'Ливийская Арабская Джамахирия', src: 'libya.webp' },
    { id: 120, title: 'Малайзия', src: 'malaysia.webp' },
    { id: 125, title: 'Марокко', src: 'morocco.webp' },
    { id: 128, title: 'Мексика', src: 'mexico.webp' },
    { id: 135, title: 'Мьянма', src: 'myanmar.webp' },
    { id: 140, title: 'Нигерия', src: 'nigeria.webp' },
    { id: 147, title: 'Объединенные Арабские Эмираты', src: 'united_arab_emirates.webp' },
    { id: 148, title: 'Оман', src: 'oman.webp' },
    { id: 157, title: 'Пакистан', src: 'pakistan.webp' },
    { id: 163, title: 'Парагвай', src: 'paraguay.webp' },
    { id: 164, title: 'Перу', src: 'peru.webp' },
    { id: 177, title: 'Саудовская Аравия', src: 'saudi_arabia.webp' },
    { id: 190, title: 'Сингапур', src: 'singapore.webp' },
    { id: 203, title: 'Таиланд', src: 'thailand.webp' },
    { id: 204, title: 'Тайвань', src: 'taiwan_republic_of_china_.webp' },
    { id: 205, title: 'Танзания, Объединенная Республика', src: 'tanzania.webp' },
    { id: 212, title: 'Тунис', src: 'tunisia.webp' },
    { id: 214, title: 'Турция', src: 'turkey.webp' },
    { id: 219, title: 'Уругвай', src: 'uruguay.webp' },
    { id: 222, title: 'Филиппины', src: 'philippines.webp' },
    { id: 234, title: 'Чили', src: 'chile.webp' },
    { id: 238, title: 'Шри-Ланка', src: 'sri_lanka.webp' },
    { id: 239, title: 'Эквадор', src: 'ecuador.webp' },
    { id: 246, title: 'Южная Африка', src: 'south_africa.webp' },
    { id: 251, title: 'Япония', src: 'japan.webp' },
]