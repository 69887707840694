import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VForm,{ref:"form",staticClass:"alarm-form",on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"alarm-form__title"},[_vm._v(" Пожалуйста, заполните анкету. Это требуется только перед вашей первой заявкой. ")]),_vm._l((_vm.answers),function(answer,index){return _c('div',{key:index,staticClass:"alarm-form__question"},[_c('div',{staticClass:"alarm-form__question-title"},[_vm._v(_vm._s(answer.name))]),_c('div',{staticClass:"alarm-form__question-radio"},[_c(VRadioGroup,{attrs:{"row":"","rules":[v => v != null || 'Это обязательный вопрос']},model:{value:(answer.value),callback:function ($$v) {_vm.$set(answer, "value", $$v)},expression:"answer.value"}},[_c(VRadio,{attrs:{"on-icon":"mdi-checkbox-outline","off-icon":"mdi-checkbox-blank-outline","label":"Да","color":"black","value":1}}),_c(VRadio,{attrs:{"on-icon":"mdi-checkbox-outline","off-icon":"mdi-checkbox-blank-outline","label":"Нет","color":"black","value":0}})],1)],1),((answer.id == 333 || answer.id == 334) && answer.value === 1)?_c(VTextField,{staticClass:"mt-2",attrs:{"height":"40px","label":"ТНВЭД","outlined":"","color":"var(--main-orange)","solo":"","flat":""},model:{value:(answer.text_value),callback:function ($$v) {_vm.$set(answer, "text_value", $$v)},expression:"answer.text_value"}}):_vm._e()],1)}),_c(VCardActions,{staticClass:"mt-2"},[_c(VSpacer),_c(VBtn,{staticClass:"alarm-form__btn",attrs:{"color":"var(--main-orange)","rounded":"","height":"38","elevation":"0","type":"submit"}},[_vm._v(" Отправить ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }