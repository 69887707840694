export const order_invoice_form_spec =
{
    shipper_name: {
        caption: "Наименование отправителя",
        name: "shipper_name",
        required: true,
        rules: [
            v => !!v || 'Укажите наименование',
            forbiddenCharactersRule
        ],
    },

    shipper_email: {
        caption: "E-mail отправителя",
        name: "shipper_email",
        required: true,
        editable: true,
        rules: [
            v => !!v || 'Добавьте email',
            forbiddenCharactersRule
        ],
    },

    shipper_phone: {
        caption: "Телефон отправителя",
        name: "shipper_phone",
        required: true,
        editable: true,
        rules: [
            v => !!v || 'Укажите телефон',
            forbiddenCharactersRule
        ],
    },

    shipper_fio: {
        caption: "ФИО отправителя",
        name: "shipper_fio",
        required: true,
        editable: true,
        rules: [
            v => !!v || 'Укажите ФИО отправителя',
            forbiddenCharactersRule
        ],
    },

    shipper_address: {
        caption: "Адрес отправителя",
        name: "shipper_address",
        required: true,
        editable: true,
        rules: [
            v => !!v || 'Укажите адрес',
            forbiddenCharactersRule
        ],
    },

    invoice_number: {
        caption: "Инвойс",
        name: "invoice_number",
        required: true,
        editable: false,
        rules: [
            v => !!v || 'Укажите номер',
            forbiddenCharactersRule
        ],
    },
}

function forbiddenCharactersRule(value) {
    if (!value) return true;
  
    const pattern = /[`"']/g;
    const invalidChars = Array.from(new Set(value.match(pattern)));
  
    return !invalidChars.length
      ? true
      : `Присутствуют запрещенные символы: ${invalidChars.join(', ')}`;
  }  

