<script>
import RequestFormInvoice from "@/components/request-form/invoice-tab/RequestFormInvoice.vue";
import AppDropdownOption from "@/components/ui/AppDropdownOption.vue";
import RequestFormInvoiceCard from "@/components/request-form/invoice-tab/RequestFormInvoiceCard.vue";
import AppButton from "@/components/ui/AppButton.vue";
import md5 from "md5";

export default {
  name: "RequestFormInvoices",

  props: {
    rate: { type: Object, required: true },
    storeData: { Object, default: null },
  },

  components: {
    RequestFormInvoice,
    AppDropdownOption,
    RequestFormInvoiceCard,
    AppButton
  },

  data() {
    return {
      invoices: [],
      showAddForm: false,
      selectedInvoice: null,
      editForm: false,
      ikey: null,
      activeForm: false,
    }
  },

  watch: {
    storeData: {
      handler() {
        this.fillFromStore()
      },
      deep: true,
    },
  },

  mounted() {
    this.showAddForm = !this.invoices.length > 0
  },

  methods: {
    addInvoice(data) {
      this.invoices.push(data)
      this.showAddForm = false
      if (this.invoices.length === 1) {
        this.selectedInvoice = this.invoices[0]
      }
      this.$emit('addInvoice', this.invoices);
    },

    validate() {
      if (this.showAddForm || this.editForm) {
        return this.$refs.invoiceForm.validateForm()
      }
    },

    fillFromStore() {
      if (this.storeData) {
        this.invoices = this.storeData.invoices
        this.showAddForm = !this.invoices.length > 0
        this.selectedInvoice = this.invoices[0]
      }
    },

    removeInvoice(number) {
      this.editForm = false
      let index = this.invoices.findIndex(el =>  el.invoice_number === number)
      this.invoices.splice(index, 1)
      this.selectedInvoice = this.invoices[0]
      if (this.invoices.length === 0) {
        this.showAddForm = true
      }
    },

    editInvoice(data){
      this.editForm = data.activeForm
      this.ikey = data.ikey
      this.activeForm = data.activeForm
    },

    getKey(item) {
      return md5(item.shipper_name + item.invoice_number)
    },

    fillInvoice(data) {
      let index = this.invoices.findIndex(el => md5(el.shipper_name + el.invoice_number) === this.ikey)
      this.invoices[index].shipper_name = data.shipper_name
      this.invoices[index].invoice_number = data.invoice_number
      this.invoices[index].shipper_email = data.shipper_email
      this.invoices[index].shipper_phone = data.shipper_phone
      this.invoices[index].shipper_fio = data.shipper_fio
      this.invoices[index].shipper_address = data.shipper_address
      this.invoices[index].operation = 0
      this.editForm = false
      this.$emit('addInvoice', this.invoices);
    },

    hideFormInvoice(){
      this.showAddForm = false
      this.editForm = false
      this.activeForm = false
    },
  }
}
</script>

<template>
  <div class="inv-tab">
    <div class="inv-container" v-if="invoices.length > 0">
      <div class="inv-container__name-list" >
        <span>Загруженные инвойсы:</span>
        <div
          v-for="invoice in invoices"
          :key="getKey(invoice)"
          class="inv-container__name-list__title"
        >
          <AppDropdownOption
            :displayTag="invoice.invoice_number"
            :is-selected="selectedInvoice.invoice_number === invoice.invoice_number"
            :item="invoice"
            @select="selectedInvoice = $event"
          />
        </div>
      </div>

      <div class="inv-container__card-list">
        <RequestFormInvoiceCard
          :ikey="getKey(selectedInvoice)"
          class="inv-container__card-list__card"
          :invoice="selectedInvoice"
          @deleteInvoice="removeInvoice"
          @editInvoice="editInvoice"
          :activeForm="activeForm"
        />
      </div>
    </div>

    <div class="inv-tab__btn-container" v-if="invoices.length > 0 && !editForm && !showAddForm" >
      <AppButton
        class="inv-tab__btn-container-btn"
        @click="showAddForm = true"
        label="Добавить инвойс"
        color="orange"
      />
    </div>

    <RequestFormInvoice
      v-if="showAddForm || editForm"
      :invoice="selectedInvoice"
      :edit_form="editForm"
      :rate="rate"
      ref="invoiceForm"
      class="inv-tab__form"
      @editInvoice="fillInvoice"
      @addInvoice="addInvoice"
      @hideFormInvoice="hideFormInvoice"
    />
  </div>
</template>

<style scoped lang="scss">
.inv-tab {
  &__form {
    width: 100%;
    position: relative;
  }

  &__btn-container {
    display: flex;
    margin-top: 16px;
    //width: 30%;
    position: relative;
    justify-content: flex-end;

    &-btn {
      width: 30%;
    }
  }
}

.inv-container {
  display: flex;
  height: 100%;
  width: 100%;
  gap: 24px;
  
  @media (max-width: $mobile-width) {
    flex-direction: column;
  }

  &:last-child {
    width: 100%;
  }

  &__name-list {
    width: 190px;
    position: relative;
    display: flex;
    flex-direction: column;

    > span {
      color: var(--grey-70);
      @include font-description-1;
    }
    
    @media (max-width: $mobile-width) {
      width: calc(100% - 16px);
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      overflow-x: auto;
    }

    &__title {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
    }
  }

  &__card-list {
    width: 100%;
    position: relative;

    &__card {
      margin-top: 28px;
    }
  }
}
</style>