<script>
import AppButton from "../ui/AppButton.vue";
export default {
  name: "RequestFormNotification",

  components: {
    AppButton,
  },

  props: {
    notificationType: {
      type: String,
      default: "",
      validator: (value) =>
        ["success", "continue", "error", "exit"].includes(value),
    },

    show:  Boolean,
  },

  data() {
    return {
      image: "",
      tryNumber: 2,
      error: true,
    };
  },

  mounted() {
    this.image = `/request-form-notification-img/${this.notificationType}.webp`
  },

  methods: {
    close() {
      this.$emit('closeNotification')
    },

    onImageError() {
      if (this.notificationType === 'error') {
        this.image = require('@/assets/img/error.webp');
      }
    },

    exit(type) {
      if (type === 'continue') {
        this.close()
      } else {
        this.$emit('exit')
      }
    },

    save(type) {
      if (type === 'continue') {
        this.$emit('continue')
      } else {
        this.$emit('save')
      }
    },

    onLoading() {
      this.tryNumber > 0 && this.tryNumber < 10
        ? (this.tryNumber = this.tryNumber + 2)
        : null
    },
  },
};
</script>

<template>
  <div class="notification-plate" v-if="show">
    <div class="notification-plate__items">
      <img
        class="notification-plate__close"
        src="../../assets/img/icons/cancel.svg"
        alt="close"
        v-if="notificationType === 'continue' || notificationType === 'exit'"
        @click="close"
      />
      <img
        class="notification-plate__image"
        :src="image"
        alt="Notification plate"
        @error="onImageError"
      />
      <p class="notification-plate__title">
        <slot name="title"></slot>
      </p>
      <p class="notification-plate__subtitle">
        <slot name="subtitle"></slot>
      </p>
      <div
        v-if="notificationType === 'continue' || notificationType === 'exit'"
        class="notification-plate__btns"
      >
        <AppButton
          :label="notificationType === 'continue' ? 'Отменить' :'Не сохранять'"
          :size="'large'" :color="'grey'"
          @click="exit(notificationType)"
        />
        <AppButton
          :label="notificationType === 'continue' ? 'Продолжить' : 'Сохранить'"
          :size="'large'"
          :color="'blue'"
          @click="save(notificationType)"
        />
      </div>

      <v-progress-linear
        class="notification-plate__proggresive-bar"
        v-if="notificationType === 'error'"
        :value="`${tryNumber}0`"
        rounded
        height="8"
        color="#6791CF"
        background-color="#F3F6FA"
      ></v-progress-linear>
    </div>
  </div>
</template>

<style scoped lang="scss">
.notification-plate {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  background: rgba(67, 79, 91, 0.3);
  backdrop-filter: blur(2px);
  height: 100%;
  width: 100%;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1000;

  &__close {
    position: absolute;
    top: 25px;
    right: 25px;
    min-width: 16px;
    min-height: 16px;
    cursor: pointer;
  }

  &__items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    max-width: 800px;
    max-height: 552px;
    width: 100%;
    height: 100%;

    border-radius: 32px;
    background: #fff;
    padding: 79px 42px 36px;

    @media (max-width: $mobile-width){
      padding: 39px 22px 16px;
      max-height: 670px;
    }
  }

  &__image {
    width: 100%;
    max-height: 250px;
    object-fit: contain;
    margin-bottom: 26px;
  }

  &__title {
    > * {
      @include font-headline-2;
      color: var(--grey-100);
      text-align: center;
      font-size: 32px;
      line-height: 32px;
      letter-spacing: 0.32px;

      margin-bottom: 16px;
    }
  }

  &__subtitle {
    > * {
      @include font-body-2;
      color: var(--grey-70);
      text-align: center;
      letter-spacing: 0.16px;

      max-width: 600px;
    }
  }

  &__btns {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px 32px;
    margin-top: 40px;

    .btn {
      width: 220px;
    }
  }

  .v-progress-linear__determinate {
    width: 100% !important;
  }

  &__proggresive-bar {
    margin-top: 25px;
  }
}
</style>